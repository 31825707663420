<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('售后人员附加分管理')}}</span>
        <div>
          <el-button type="primary"
          @click='dialogForm'
          v-if="user_role['additionalPoints/add']" size="small">
          {{$t('新增附加分')}}
          </el-button>
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <div style="margin:15px 0; width: 250px;">
        <el-autocomplete class="inline-input" v-model="username" :fetch-suggestions="querySearch"
            :placeholder="$t('输入人员名称搜索')" value-key="username" :trigger-on-focus="false" clearable @select="handleSelect">
            <el-button slot="append" icon="el-icon-search" @click="getUserList(1)"></el-button>
            </el-autocomplete>
        </el-form-item>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('管理员ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('得分人员')" prop="username" width="200">
        </el-table-column>
        <el-table-column :label="$t('得分')" prop="fraction" width="200">
        </el-table-column>
        <el-table-column :label="$t('得分日期')" prop="score_time" width="160">
        </el-table-column>
        <el-table-column :label="$t('备注信息')" prop="remarks">
        </el-table-column>
        <el-table-column :label="$t('状态')">
          <template slot-scope="scope">
            <span>{{scope.row.status==1?$t('有效'):$t('无效')}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="setStatus(scope)" v-if="user_role['additionalPoints/setStatus']">{{$t('变更状态')}}</el-button>
            <el-button type="text"
            @click='dialogForm(scope,true)'
            v-if="user_role['additionalPoints/edit']" size="small">
            {{$t('修改')}}
            </el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['additionalPoints/delete']" size="small">{{$t('删除')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('添加附加分项')" :visible.sync="addAdmin" width="70%">
      <el-row :gutter="20">
        <el-form :model="addform" :rules="addformRules" label-position="left" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('得分人员')" required prop="uid">
              <el-autocomplete class="inline-input" v-model="prepare_uid" :fetch-suggestions="querySearch"
                value-key="username" value="id" :trigger-on-focus="false" clearable @select="handleSelect"></el-autocomplete>
            </el-form-item>
          </el-col><!-- /得分人员 -->
          <el-col :span="12">
            <el-form-item :label="$t('得分')" prop="fraction">
              <el-input v-model="addform.fraction" type="number" clearable></el-input>
            </el-form-item>
          </el-col><!-- /得分 -->
          <el-col :span="12">
            <el-form-item :label="$t('得分日期')" prop="score_time">
              <el-date-picker
                v-model="addform.score_time"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col><!-- /得分日期 -->
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="addform.remarks" type="textarea" rows="5" show-word-limit :maxlength="255"></el-input>
            </el-form-item>
          </el-col><!-- /备注信息 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAddDia">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改附加分项')" :visible.sync="editAdmin" width="50%">
      <el-row :gutter="20">
        <el-form :model="editform" label-position="left" label-width="100px" ref="editform">
          <el-col :span="24">
            <el-form-item :label="$t('得分')" required prop="fraction" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.fraction" type="number" placeholder="0-10" clearable></el-input>
            </el-form-item>
          </el-col><!-- /得分 -->
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" required prop="remarks" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.remarks" type="textarea" rows="5" show-word-limit :maxlength="255"></el-input>
            </el-form-item>
          </el-col><!-- /备注信息 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!-- -->
  </div>
</template>

<script>
export default {
  name: 'extraPoint',
  data () {
    return {
      addAdmin: false,
      editAdmin: false,
      UntreatedData: [],
      username: '',
      prepare_uid: '',
      addform: {
        uid: '',
        score_time: '',
        fraction: '',
        remarks: ''
      },
      editform: {
        id: '',
        fraction: '',
        remarks: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      department: [],
      role: [],
      user_role: {},
      addformRules: {
        fraction: [
          { required: true, message: this.$t('不能为空'), trigger: 'change' }
        ],
        score_time: [
          { required: true, message: this.$t('不能为空'), trigger: 'change' }
        ],
        remarks: [
          { required: true, message: this.$t('不能为空'), trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    this.$store.dispatch('getAdmin')
  },
  computed: {
    minList () {
      return this.$store.state.minList
    }
  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        // console.log(item)
        this.editform = this.$common.forData2(item.row, this.editform)
        this.editAdmin = true
      } else {
        this.addAdmin = true
      }
    },
    // 获取附加分列表
    getUserList (page, is_report) {
      var params = {
        page: this.Page.page,
        username: this.username
      }
      if (page) params.page = page
      if (is_report) {
        params.is_report = is_report
        this.$common.postExcelFile({
          is_report
        }, 'additionalPoints/lists')
        return
      }
      this.$axios.post('additionalPoints/lists', params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        }
      }).catch((error) => console.log(error))
    },
    // 添加附加分
    addMin () {
      var params = {}; var key
      for (key in this.addform) {
        if (this.addform[key] !== '' && this.addform[key] !== null) params[key] = this.addform[key]
        else return alert(`${key}${this.$t('不得为空')}`)
      }
      if (params.fraction < 0 || params.fraction > 10) return alert( this.$t('得分不能小于0或大于10'))
      this.$axios.post('additionalPoints/add', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.closeAddDia()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 修改附加分
    editMin () {
      // console.log(this.editform.r_id, this.editform.bm_id)
      var params = {}; var key
      for (key in this.editform) {
        if (this.editform[key] !== '' && this.editform[key] !== null) params[key] = this.editform[key]
        else return alert(`${key}${this.$t('不得为空')}`)
      }
      this.$axios.post('additionalPoints/edit', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.editAdmin = false
          var that = this
          this.$common.resetField('editform', that)
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 删除附加分
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm( that.$t('是否永久删除该项目?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('additionalPoints/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message:  that.$t('已取消删除')
        })
      })
    },
    // 修改状态
    setStatus (scope) {
      let that = this
      this.$MessageBox.confirm(that.$t('是否修改该项目状态？'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('additionalPoints/setStatus', {
          id: scope.row.id,
          status: !scope.row.status
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    querySearch (queryString, cb) {
      var results = queryString ? this.minList.filter(this.createFilter(queryString)) : this.minList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (minList) => {
        return minList.username.indexOf(queryString) === 0
      }
    },
    handleSelect (item) {
      // console.log(item);
      this.addform.uid = item.id
    },
    closeAddDia () {
      this.$refs.addform.resetFields()
      this.prepare_uid = ''
      this.addAdmin = false
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select,
  .el-autocomplete,
  .el-date-editor{
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
  ::v-deep .el-dialog{
    border-radius: 10px;
    min-width: 300px;
  }
</style>
